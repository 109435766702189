
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}





:root {
  --background-color: #FAFAFA;
  --primary-green: #30606B;
  --header-banner-green: #00282B;
  --link-active-darkmode-blue: #9BCFDB;
  --link-hover-darkmode-blue: #79BFCF;
}

.item {
  font-size: 0.5em;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  background-color: white;
}

.item:hover {
  cursor: pointer;
}

.item-title {
  font-size: 2.0em;
  overflow-wrap: break-word;
  text-align: left;
}

.color-bar {
  width: 40px;
  height: 10px;
  border-radius: 5px;
}

.drop-wrapper {
  flex: 1 25%;
  width: 100%;
  /* height: 100%; */
  /* min-height: 350px;
  max-height: 350px; */
  min-height: 60vh;
  max-height: 60vh;
  /* height: 100vh; */
  overflow-y: auto;
}

.col-wrapper {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.col-header {
  font-size: 0.8em;
  /* font-weight: 600; */
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0;
}

.col {
  min-height: 300px;
  /*max-width: 300px; */
  /* overflow-y: scroll; */
  /* width: 300px; */
}

/* .highlight-region {
  background-color: #D3D3D3;
} */

.ant-tag-blue {
  color: #58AFC3 !important;
  background: #b1e4f0 !important;
  border-color: #b1e4f0 !important;
  font-weight: 600;
}

.ant-tag-green {
  color: #5BC16B !important;
  background: #A1E8D0 !important;
  border-color: #A1E8D0 !important;
  font-weight: 600;
}

.ant-tag-volcano {
  color: #FF904E !important;
  background: #FFC6A4 !important;
  border-color: #FFC6A4 !important;
  font-weight: 600;
}

.ant-tag-cyan {
  color: #BE52F2 !important;
  background: #DEA8F8 !important;
  border-color: #DEA8F8 !important;
  font-weight: 600;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #001314 !important;
}