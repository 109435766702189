.m-40 {
  margin: 40px 40px 0;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-45 {
  margin-bottom: 45px;
}
