
.sider {
    height: 100%;
}
.header-logo-small {
    margin: 2vh;
    font-size: 8pt;
    font-weight: bolder;
}
.header-logo-big {
    margin: 2vh;
    font-size: 24pt;
    font-weight: bolder;
}

.ant-menu {
    color: var(--background-color);
    background-color: var(--header-banner-green);
    border-right: none;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
color: var(--header-banner-green);
} 

.ant-menu-submenu-title > .ant-menu-submenu-arrow  {
    color: var(--background-color);;
}

.ant-menu-submenu-selected, .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow  {
    color: var(--link-active-darkmode-blue) !important;
}

.ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    color: var(--link-hover-darkmode-blue) !important;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-layout-sider-trigger:hover {
    color: var(--link-hover-darkmode-blue);
}

.flex-center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
