.dashboard-container {
    margin-top: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    max-height: 85vh;
}

.search-row {
    margin-bottom: 2vh;
}

.window-row {
    margin-bottom: 2vh;
    width: 100;
}

.boxknight-logo-login {
    width: 100%;
}

.logo-row {
    margin-bottom: 3vh;
    width: 100%;
    font-size: 4.1vmin;
}

.login-button {
    padding: 0 7vmin 0 7vmin !important;
}

.login-input {
    width: 100%;
}

.ant-form-item-control {
    line-height: 10px;
}

.order-count-button {
    color: rgba(0, 0, 0, 0.85) !important;
    background: transparent !important;
    border-color: transparent !important;
  }
