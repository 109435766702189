.login-container {
    margin: 0 auto;
    margin: 0 auto;
}

.boxknight-logo-login {
    width: 100%;
}

.logo-row {
    margin-bottom: 3vh;
    width: 100%;
    font-size: 4.1vmin;
}

.login-button {
    padding: 0 7vmin 0 7vmin !important;
}

.login-input {
    width: 40vh;
}

.ant-form-item-control {
    line-height: 10px;
}
.login-title {
    font-weight: bolder;
    font-size: 28pt;
    color: #1890FF;
}
