.singleDriverProfile-container {
    margin-top: 2vh;
    margin-left: 2vw;
    margin-right: 2vw;
    max-height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5;
}

.search-row {
    margin-bottom: 2vh;
}
.boxknight-logo-login {
    width: 100%;
}

.logo-row {
    margin-bottom: 3vh;
    width: 100%;
    font-size: 4.1vmin;
}

.login-button {
    padding: 0 7vmin 0 7vmin !important;
}

.login-input {
    width: 100%;
}

.ant-form-item-control {
    line-height: 10px;
}

.addDriver-title {
    font-weight: bolder;
    font-size: 28pt;
    color: #1890FF;
}